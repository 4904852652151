//选择客户
<template>
  <el-select
    v-model="v"
    remote
    reserve-keyword
    filterable
    :placeholder="$t('pleaseEnter')"
    @change="changeEvent"
    :remote-method="
      v => {
        $utils.debounce(remoteMethod, 1000)(v)
      }
    "
    v-bind="$attrs"
  >
    <el-option v-for="a in list" :key="a.spId" :value="a.spId" :label="a.name" />
  </el-select>
</template>

<script>
export default {
  name: 'SelectCustomer',
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      v: '',
      list: []
    }
  },
  watch: {
    name: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        this.v = newV
      }
    }
  },
  methods: {
    async remoteMethod(query) {
      try {
        let params = { name: query }
        let res = await this.$api.systemManagement.spList({
          ...params,
          pageSize: -1
        })
        console.log(res)
        this.list = (res.result && res.result.list) || []
      } catch (err) {
        console.log(err)
      }
    },
    changeEvent(spId) {
      let a = this.list.find(_ => _.spId === spId) || {}
      this.$emit('update:value', spId)
      this.$emit('update:name', a.name)
      this.$emit('change', a)
    }
  }
}
</script>
