<template>
  <div class="login-wrap">
    <div class="language-warp" v-if="show">
      <div
        class="language-list"
        v-for="item in languageList"
        :key="item.key"
        @click="setLanguage(item)"
      >
        {{ item.value }}
      </div>
    </div>
    <div class="login" :class="{ 'login-en': $i18n.locale == 'en' }">
      <div class="title">
        <h1 v-if="PLATFORM === 'service'">{{ $t('loginPage.customerPlatform') }}</h1>
        <h1 v-else>{{ $t('loginPage.sdyPlatform') }}</h1>
      </div>
      <el-form :model="form" :rules="rules" size="small" ref="form">
        <el-form-item prop="loginName">
          <el-input
            v-model.trim="form.loginName"
            prefix-icon="el-icon-s-custom"
            :placeholder="$t('loginPage.accountPlaceholder')"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            @keyup.enter.native="submitForm"
            type="password"
            v-model.trim="form.password"
            prefix-icon="el-icon-lock"
            :placeholder="$t('loginPage.passwordPlaceholder')"
          />
        </el-form-item>

        <div class="flex" v-if="needImageCode">
          <el-form-item prop="imageCode">
            <el-input
              @keyup.enter.native="submitForm"
              type="text"
              v-model.trim="form.imageCode"
              :placeholder="$t('loginPage.verificationCode')"
            />
          </el-form-item>
          <div class="imageWrap">
            <img :src="imageCodeSrc" @click="time = Date.now()" />
          </div>
        </div>

        <el-form-item>
          <el-button class="login-btn" @click="submitForm">
            {{ !loginStatus ? $t('loginPage.login') : $t('loginPage.loggingIn') }}
            <span v-if="loginStatus" class="el-icon-loading"></span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Qs from 'qs'
import { BASE_URL, PLATFORM } from '@/lib/constant'

export default {
  data() {
    return {
      PLATFORM,
      isFirstIn: true,
      form: {
        loginName: '',
        password: '',
        imageCode: ''
      },
      time: '',
      needImageCode: false,
      loginStatus: false,
      rules: {
        loginName: [{ required: true, message: this.$t('loginPage.accountPlaceholder') }],
        password: [{ required: true, message: this.$t('loginPage.passwordPlaceholder') }],
        imageCode: [{ required: true, message: this.$t('loginPage.verificationCode') }]
      },
      languageList: [
        { key: 'zh', value: '简体中文' },
        { key: 'en', value: 'English' }
        // {key:'ruRU',value:'русский язык'},
      ]
    }
  },
  created() {
    if (process.env.NODE_ENV == 'production') {
      // 默认使用英语
      this.setLanguage({ key: 'en', value: 'English' })
    }
  },
  methods: {
    submitForm() {
      if (this.loginStatus) return
      this.$refs['form'].validate(async valid => {
        if (valid) {
          // this.$router.push('/deviceManagement')

          const data = { ...this.form }

          this.loginStatus = true
          const sha256 = require('js-sha256').sha256
          data.password = sha256(data.password + this.$store.state.privateKey)

          try {
            const res = await this.$api.common.login(Qs.stringify(data))

            if (res.code === 'OK') {
              const data = res.result.user
              if (res.result && data) {
                sessionStorage.setItem('username', data.accountCode)
                sessionStorage.setItem('spId', data.spId)
                sessionStorage.setItem('userId', data.userId)

                let vStr = ''

                if (!data.expirationDate) {
                  vStr = this.$t('loginPage.longTerm')
                } else {
                  const timeStamp = Math.ceil(
                    (+new Date(data.expirationDate) - Date.now()) / (24 * 3600 * 1000)
                  )

                  vStr =
                    timeStamp > 0
                      ? this.$t('loginPage.leave') + timeStamp + this.$t('loginPage.day')
                      : this.$t('loginPage.expired')
                }

                sessionStorage.setItem('daysRemaining', vStr)
              }

              this.$router.push('/home')
              // this.$router.push('/deviceManagement')
            } else {
              if (!this.isFirstIn) {
                this.time = Date.now()
              }

              if (res.code === 'SCP010402' || res.code === 'SCP010502') {
                this.needImageCode = true
                this.isFirstIn = false
              }

              this.$message({
                message: res.summary || this.$t('systemError'),
                type: 'error',
                duration: 5 * 1000
              })
            }
          } catch (err) {
            console.log(err)
          } finally {
            this.loginStatus = false
          }
        }
      })
    },
    setLanguage(item) {
      sessionStorage.setItem('language', item.key)
      this.$i18n.locale = item.key
      if (location.pathname.includes('admin.html')) {
        document.title = this.$t('loginPage.sdyPlatform')
      } else {
        document.title = this.$t('loginPage.customerPlatform')
      }
      this.resetForm()
      this.resetData()
    },
    resetData() {
      this.rules.loginName[0].message = this.$t('loginPage.accountPlaceholder')
      this.rules.password[0].message = this.$t('loginPage.passwordPlaceholder')
      this.rules.imageCode[0].message = this.$t('loginPage.verificationCode')
    },
    resetForm() {
      this.$refs.form && this.$refs.form.resetFields()
    }
  },
  computed: {
    imageCodeSrc() {
      return BASE_URL + '/picture-verification/api/getImageCode?t=' + this.time
    },
    show() {
      return process.env.NODE_ENV == 'development'
    }
  }
}
</script>

<style scoped lang="scss">
.login-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('../../assets/img/bg.jpg');
  background-size: 100% 100%;
  position: relative;
  .language-warp {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
  }
  .language-list {
    padding-right: 15px;
    color: #333;
    cursor: pointer;
  }
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 400px;
    height: 330px;
    padding: 20px 20px 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to bottom right, #878787, #515151);
    border-radius: 10px;
    .title {
      margin-bottom: 26px;
      color: #fff;
      h1 {
        font-weight: normal;
      }
    }
    .el-form {
      width: 90%;

      ::v-deep .el-input__icon {
        font-size: 16px;
      }
      .imageWrap {
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        width: 144px;
        height: 34px;
        margin-left: 14px;
      }
      .login-btn {
        cursor: pointer;
        width: 100%;
        background: #ef2b23;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border-color: rgba(0, 0, 0, 0.2);
        border-radius: 0;
        height: 34px;
        text-align: center;
        transition: all 0.3s ease;
        &:hover {
          // background: rgba(68, 105, 154, 0.8);
          opacity: 0.8;
        }
      }
    }
  }
  .login-en {
    width: 500px;
  }
  .serviceTag {
    color: #dad2d2;
    font-size: 14px;
    text-align: center;
  }
}
</style>
