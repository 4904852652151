import axios from '../lib/request.js'

const api = {
  //首页统计
  getCountSummary: data => axios.post('/device/statistics/statisticAnalysis', data),

  //登录
  login: data => axios.post('/login', data),

  //登出
  logout: data => axios.get('/logout')
}

export default api
