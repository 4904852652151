import axios from '../lib/request.js'

const api = {
  //素材列表
  materialList: data => axios.post('/marketing/source/getSources', data),

  //素材删除
  deleteMaterial: params => axios.delete('/marketing/source/deleteSource', { params }),

  //素材修改
  updateMaterial: data => axios.post('/marketing/source/updateSource', data),

  //素材下载
  downloadMaterial: params => axios.get(`/marketing/source/sourceDownload`, { params }),

  //投放列表
  programList: data => axios.post('/marketing/delivery/getDelivery', data),

  //投放增加
  programAdd: data => axios.post('/marketing/delivery/addDelivery', data),

  //投放编辑
  programUpdate: data => axios.post('/marketing/delivery/updateDeliveryInfo', data),

  //投放状态更改
  programStatus: data => axios.post('/marketing/delivery/updateDeliveryStatus', data),

  //校验投放节目是否冲突
  checkSourceLaunchInfo: data => axios.post('/marketing/delivery/checkSourceLaunchInfo', data)
}

export default api
