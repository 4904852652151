<template>
  <div class="wrap">
    <h3>
      {{ $t('customerHome.versionStatistics') }} ({{ $t('customerHome.firmwareCloudPlatform') }})
    </h3>
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
      <span v-if="chartData.length" class="name">{{
        $t('customerHome.firmwareVersionStatistics')
      }}</span>
      <span v-if="chartData.length" class="name name2">{{
        $t('customerHome.cloudPlatformVersionStatistics')
      }}</span>
      <div v-if="!chartData.length" class="no-data">{{ $t('noData') }}</div>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        color: [
          // '#fe6f44',
          '#ef2b23',
          '#ff8443',
          '#3d6aeb',
          '#a6bc72',
          '#8f83ff',
          '#3c93d0'
        ],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
          confine: true
        },
        legend: [
          {
            // orient: 'vertical',
            data: [],
            pageIconColor: '#222222', //翻页箭头颜色
            pageIconInactiveColor: 'rgba(44,132,251,0.40)', //翻页（即翻页到头时箭头的颜色）
            pageTextStyle: {
              color: '#222222' //图例分页页码的颜色设置
            },
            icon: 'circle',
            itemWidth: 8,
            itemGap: 20,
            height: '40%',
            width: '90%',
            top: '46%',
            right: 'center',
            textStyle: {
              color: '#222222'
            },
            data: [],
            type: 'scroll'
          },
          {
            // orient: 'vertical',
            data: [],
            pageIconColor: '#222222', //翻页箭头颜色
            pageIconInactiveColor: 'rgba(44,132,251,0.40)', //翻页（即翻页到头时箭头的颜色）
            pageTextStyle: {
              color: '#222222' //图例分页页码的颜色设置
            },
            icon: 'circle',
            itemWidth: 8,
            itemGap: 20,
            height: '40%',
            width: '90%',
            top: '90%',
            right: 'center',
            textStyle: {
              color: '#222222'
            },
            data: [],
            type: 'scroll'
          }
        ],
        series: [
          {
            name: this.$t('customerHome.firmwareVersionStatistics'),
            type: 'pie',
            radius: ['17%', '30%'],
            center: ['50%', '32%'],
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                formatter: '{d}%'
              },
              labelLine: {
                show: true
              }
            },
            data: []
          },
          {
            name: this.$t('customerHome.cloudPlatformVersionStatistics'),
            type: 'pie',
            radius: ['17%', '30%'],
            center: ['50%', '76%'],
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                formatter: '{d}%'
              },
              labelLine: {
                show: true
              }
            },
            data: []
          }
        ]
      }
    }
  },
  methods: {
    render(type, array = []) {
      const legend = []
      const data = []

      array.forEach(item => {
        if (type == 1) {
          // 固件
          if (item.firmwareVersion) {
            let legendTitle = item.firmwareVersion
            // let len = item.firmwareVersion.length
            // let legendTitle =
            //   len > 18
            //     ? item.firmwareVersion.substring(0, 7) +
            //       '...' +
            //       item.firmwareVersion.substr(len - 8, 8)
            //     : item.firmwareVersion
            // legend.push(legendTitle)
            if (legendTitle == '-') {
              legendTitle += ' ' // 拼接一个空格，避免与下面的图例相同，相互影响
            }
            legend.push(legendTitle)
            data.push({
              name: legendTitle,
              value: item.deviceNumber || 0
            })
          }
        } else {
          // 云平台
          if (item.cloudPlatformVersion) {
            // const legendTitle = item.systemVersion || item.cloudPlatformVersion || '-'
            legend.push(item.cloudPlatformVersion)
            data.push({
              name: item.cloudPlatformVersion,
              value: item.deviceNumber || 0
            })
          }
        }
        // const legendTitle = item.systemVersion || item.cloudPlatformVersion || '-'
        // legend.push('Android ' + legendTitle)
        // data.push({
        //   name: 'Android ' + legendTitle,
        //   value: item.deviceNumber
        // })
      })
      console.log({ data, legend })

      return { data, legend }
    }
  },
  watch: {
    chartData(val) {
      if (val && val.length) {
        const obj1 = this.render(1, val[0]) //固件
        const obj2 = this.render(2, val[1]) // 云平台

        this.opt.legend[0].data = obj1.legend
        this.opt.legend[1].data = obj2.legend
        this.opt.series[0].data = obj1.data
        this.opt.series[1].data = obj2.data
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;
  h3 {
    font-weight: normal;
    color: #222222;
    font-size: 16px;
    padding: 30px 20px;
  }
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }

    .name {
      color: #222222;
      font-size: 12px;
      position: absolute;
      left: 50%;
      top: 51%;
      transform: translate(-50%, 0);
      text-align: center;
    }
    .name2 {
      top: 95%;
    }

    .solid,
    .cloud {
      position: absolute;
      color: #222222;
      font-size: 12px;
    }

    .solid {
      top: 49%;
      left: 22%;

      @media screen and (min-width: 2560px) {
        left: 25%;
      }

      @media screen and (max-width: 1526px) {
        left: 18%;
      }
    }
    .cloud {
      top: 93%;
      left: 20%;

      @media screen and (min-width: 2560px) {
        left: 25%;
      }

      @media screen and (max-width: 1526px) {
        left: 16%;
      }
    }
  }
}
</style>
