import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

const state = {
  privateKey: 'rluz9wmszcf',
  origin: '',
  username: '',
  spId: '',
  currentPage: '',
  currentTitle: '',
  headerLink: [],
  currentTab: '',
  headerTabs: [] // 面包屑导航
}
export default new Vuex.Store({
  state,
  mutations,
  actions
})
