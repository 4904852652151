import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Element from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale'
import elEnLocale from 'element-ui/lib/locale/lang/en'
import elZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elRuRU from 'element-ui/lib/locale/lang/ru-RU'
import en from './lang/en.js'
import zhCN from './lang/zh-CN.js'
import ruRU from './lang/ru-RU.js'
Vue.use(VueI18n)
const messages = {
  en: {
    ...en,
    ...elEnLocale
  },
  zh: {
    ...zhCN,
    ...elZhLocale
  },
  ruRU: {
    ...ruRU,
    ...elRuRU
  }
}
const getLanguage = () => {
  var type = navigator.appName
  var lang = ''
  if (type == 'Netscape') {
    lang = navigator.language //获取浏览器配置语言，支持非IE浏览器
  } else {
    lang = navigator.userLanguage //获取浏览器配置语言，支持IE5+ == navigator.systemLanguage
  }
  lang = lang.substr(0, 2) //获取浏览器配置语言前两位
  if (lang == 'ru') {
    lang = 'ruRU'
  }
  return lang
}
const i18n = new VueI18n({
  locale: sessionStorage.getItem('language') || getLanguage(),
  messages
})
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
// locale.i18n((key,value)=>{
//     i18n.t(key,value)
// })
export default i18n
