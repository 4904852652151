let usaJson = require('./usa.json')

;(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['exports', 'echarts'], factory)
  } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
    // CommonJS
    factory(exports, require('echarts'))
  } else {
    // Browser globals
    factory({}, root.echarts)
  }
})(this, function(exports, echarts) {
  var log = function(msg) {
    if (typeof console !== 'undefined') {
      console && console.error && console.error(msg)
    }
  }
  if (!echarts) {
    log('ECharts is not Loaded')
    return
  }
  if (!echarts.registerMap) {
    log('ECharts Map is not loaded')
    return
  }
  echarts.registerMap('USA', usaJson, {
    // 把阿拉斯加移到美国主大陆左下方
    Alaska: {
      // 左上角经度
      left: -131,
      // 左上角纬度
      top: 25,
      // 经度横跨的范围
      width: 15
    },
    // 夏威夷
    Hawaii: {
      left: -110,
      top: 28,
      width: 5
    },
    // 波多黎各
    'Puerto Rico': {
      left: -76,
      top: 26,
      width: 2
    }
  })
})
