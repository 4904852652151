<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
      <div v-if="!chartData.length" class="no-data">{{ $t('noData') }}</div>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/map'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import '@/lib/usa.js'
export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        backgroundColor: '#fcfcfc',
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return (
              `${params.marker}${params.name}<br/>` +
              `Total number of devices:${params.value || 0}<br/>` +
              `in-line equipment:${(params.data && params.data.onlineNumber) || 0}<br/>` +
              `off-line equipment:${(params.data && params.data.offlineNumber) || 0}<br/>`
            )
          },
          confine: true
        },
        visualMap: {
          min: 0,
          calculable: true,
          // text: [],
          textStyle: {
            color: '#222222'
          },
          type: 'continuous',
          show: true,
          orient: 'horizontal',
          bottom: 0,
          color: ['#3e6beb', '#5a80ed', '#87a2f0', '#c2cff5', '#d3dcf6']
          // color: ['#357000', '#6aa500', '#9af029', '#c6ff7d', '#f0ffb8']
        },

        //配置属性
        series: [
          {
            name: '数据',
            type: 'map',
            mapType: 'USA',
            roam: true,
            zoom: 1.2,
            scaleLimit: {
              min: 1,
              max: 20
            },
            label: {
              normal: {
                show: false, //省份名称
                backgroundColor: '#222222',
                fontSize: 13
              },
              emphasis: {
                show: false
              }
            },
            emphasis: {
              itemStyle: {
                areaColor: ''
              }
            },
            data: [
              { name: 'Alabama', value: 0 },
              { name: 'Alaska', value: 0 },
              { name: 'Arizona', value: 0 },
              { name: 'Arkansas', value: 0 },
              { name: 'California', value: 0 },
              { name: 'Colorado', value: 0 },
              { name: 'Connecticut', value: 0 },
              { name: 'Delaware', value: 0 },
              { name: 'District of Columbia', value: 0 },
              { name: 'Florida', value: 0 },
              { name: 'Georgia', value: 0 },
              { name: 'Hawaii', value: 0 },
              { name: 'Idaho', value: 0 },
              { name: 'Illinois', value: 0 },
              { name: 'Indiana', value: 0 },
              { name: 'Iowa', value: 0 },
              { name: 'Kansas', value: 0 },
              { name: 'Kentucky', value: 0 },
              { name: 'Louisiana', value: 0 },
              { name: 'Maine', value: 0 },
              { name: 'Maryland', value: 0 },
              { name: 'Massachusetts', value: 0 },
              { name: 'Michigan', value: 0 },
              { name: 'Minnesota', value: 0 },
              { name: 'Mississippi', value: 0 },
              { name: 'Missouri', value: 0 },
              { name: 'Montana', value: 0 },
              { name: 'Nebraska', value: 0 },
              { name: 'Nevada', value: 0 },
              { name: 'New Hampshire', value: 0 },
              { name: 'New Jersey', value: 0 },
              { name: 'New Mexico', value: 0 },
              { name: 'New York', value: 0 },
              { name: 'North Carolina', value: 0 },
              { name: 'North Dakota', value: 0 },
              { name: 'Ohio', value: 0 },
              { name: 'Oklahoma', value: 0 },
              { name: 'Oregon', value: 0 },
              { name: 'Pennsylvania', value: 0 },
              { name: 'Rhode Island', value: 0 },
              { name: 'South Carolina', value: 0 },
              { name: 'South Dakota', value: 0 },
              { name: 'Tennessee', value: 0 },
              { name: 'Texas', value: 0 },
              { name: 'Utah', value: 0 },
              { name: 'Vermont', value: 0 },
              { name: 'Virginia', value: 0 },
              { name: 'Washington', value: 0 },
              { name: 'West Virginia', value: 0 },
              { name: 'Wisconsin', value: 0 },
              { name: 'Wyoming', value: 0 },
              { name: 'Puerto Rico', value: 0 }
            ] //数据
          }
        ]
      }
    }
  },
  watch: {
    chartData(val) {
      if (val && val.length) {
        const data = [...this.opt.series[0].data]
        const targetData = [...val]
        let numbers = []
        val.forEach(item => {
          numbers.push(item.deviceNumber)
          let provicneName = item.province
          // if (item.province.includes('自治区') || item.province.includes('行政区')) {
          //   provicneName =
          //     item.province == '内蒙古自治区' ? '内蒙古' : item.province.substring(0, 2)
          //   console.log('provicneName', provicneName)
          //   // if(item.province=='内蒙古')
          // } else {
          //   provicneName = item.province.replace('省', '').replace('市', '')
          // }

          const index = data.findIndex(_ => _.name === provicneName)

          if (index > -1 && item) {
            data[index].value = item.deviceNumber
            data[index].onlineNumber = item.onlineNumber
            data[index].offlineNumber = item.offlineNumber
          }
        })
        this.opt.visualMap.max = Math.max(...numbers)

        console.log('data', data)

        this.opt.series[0].data = data
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100%;

  h3 {
    font-weight: normal;
    color: #222222;
    font-size: 16px;
    padding: 30px 20px;
  }

  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
